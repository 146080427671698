<template>
  <div class="main">
    <div class="videos-container">
      <div class="video-item" v-for="(item, idx) in videos" :key="idx">
              <LiteYouTubeEmbed 
                :id="getVideo(idx)"
                title="Rick Astley - Never Gonna Give You Up (Official Music Video)"
              />
              <div class="video-name">{{ getName(idx)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LiteYouTubeEmbed from 'vue-lite-youtube-embed';
import 'vue-lite-youtube-embed/dist/style.css';

export default {
  name: "Videos",
  components: { LiteYouTubeEmbed },
  data()  {
    return {
      videos : [
        {
          en : {
            url : "3TnxDqopsZE",
            name : "Next-gen towers"
          },
          fr : {
            url : "3TnxDqopsZE",
            name : "Next-gen towers"
          },
          de : {
            url : "3TnxDqopsZE",
            name : "Next-gen towers"
          }
        },
        // {
        //   en : {
        //     url : "HhkSzp6vWUM",
        //     name : "Corporate"
        //   },
        //   fr : {
        //     url : "bPgZqLa5B0Q",
        //     name : "Corporate"
        //   },
        //   de : {
        //     url : "HhkSzp6vWUM",
        //     name : "Corporate"
        //   }
        // },
        {
          en : {
            url : "nkczODf5vFg",
            name : "SteriSpineTM VA – Kyphoplasty System"
          },
          fr : {
            url : "nkczODf5vFg",
            name : "SteriSpineTM VA – Système de kyphoplastie"
          },
          de : {
            url : "nkczODf5vFg",
            name : "SteriSpineTM VA – Kyphoplastie System"
          }
        },
        {
          en : {
            url : "ZB0GUEScHb4",
            name : "SteriSpineTM LC – Lumbar Cage System"
          },
          fr : {
            url : "ZB0GUEScHb4",
            name : "SteriSpineTM LC – Système de cages lombaires"
          },
          de : {
            url : "ZB0GUEScHb4",
            name : "SteriSpineTM LC – Lumbales Cage System"
          }
        },
        {
          en : {
            url : "sB5MuQip-ps",
            name : "SteriSpineTM PS – Pedicle Screw System"
          },
          fr : {
            url : "sB5MuQip-ps",
            name : "SteriSpineTM PS – Système de vis pédiculaires"
          },
          de : {
            url : "sB5MuQip-ps",
            name : "SteriSpineTM PS – Pedikelschraubensystem"
          }
        },
        {
          en : {
            url : "9eobXV5fzaI",
            name : "Oak – Auto Correction Screw"
          },
          fr : {
            url : "9eobXV5fzaI",
            name : "Oak – Vis d’auto-correction"
          },
          de : {
            url : "9eobXV5fzaI",
            name : "Oak – Auto-Korrektionsschraube"
          }
        },
        {
          en : {
            url : "-cjyZslNae4",
            name : "Cypress – Fenestrated Polyaxial Screw"
          },
          fr : {
            url : "-cjyZslNae4",
            name : "Cypress – Vis polyaxiale fenestrée"
          },
          de : {
            url : "-cjyZslNae4",
            name : "Cypress – Polyaxiale fenestrierte Schraube"
          }
        }
      ]
    }
  },
  methods: {
    getName(e) {
      console.log("index", e)
      console.log('item', this.videos[e]);
      if(this.$i18n.locale == "en") {
        return this.videos[e].en.name;
      }
      else if (this.$i18n.locale == "fr") {
        return this.videos[e].fr.name;
      }
      else {
        return this.videos[e].de.name;
      }
    },
    getVideo(e) {
      if(this.$i18n.locale == "en") {
        return this.videos[e].en.url;
      }
      else if (this.$i18n.locale == "fr") {
        return this.videos[e].fr.url;
      }
      else {
        return this.videos[e].de.url;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  width:$box;
  max-width: $max;
  margin:40px auto;
}

.videos-container {
  width:100%;
  display: flex;
  flex-wrap: wrap;
}

.video-item {
  width:29%;
  margin:20px 2%;
}

.video-name {
  font-weight:400;
  color:#fff;
  font-size:20px;
  margin:5px 0 20px 0;
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .video-item {
    width:96%;
  }
}
</style>